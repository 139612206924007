import { createAction, props } from '@ngrx/store';
import { Airport, AirportOption, AirportsBulkUpdate } from '@features/settings/airports/airports.model';
import { DataGridColumn } from '@shared/components/data-grid/data-grid.interface';

export const airportScreenLoaded = createAction('[Airports Settings Page] Loaded Airports Settings');

export const apiLoadAirportsSuccess = createAction(
  '[Settings > Airports API] Load airports success',
  props<{ airports: Airport[] }>()
);

export const apiLoadAirportsFailed = createAction('[Settings > Airports API] Load airports failed');

export const addAirportButtonClicked = createAction(
  '[Settings > Airports Page] Add airport button clicked',
  props<{ airport: Airport }>()
);

export const apiAddAirportSuccess = createAction(
  '[Settings > Airports API] Add airport success',
  props<{ airport: Airport }>()
);

export const apiAddAirportFailed = createAction('[Settings > Airports API] Add airport failed');

export const updateAirportButtonClicked = createAction(
  '[Settings > Airports Page] Update airport button clicked',
  props<{ airport: Airport }>()
);

export const apiUpdateAirportSuccess = createAction(
  '[Settings > Airport API] Update airport success',
  props<{ airport: Airport }>()
);

export const apiUpdateAirportFailed = createAction('[Settings > Airport API] Update airport failed');

export const deleteAirportButtonClicked = createAction(
  '[Settings > Airports Page] Delete airport button clicked',
  props<{ airportCode: string }>()
);

export const apiDeleteAirportSuccess = createAction(
  '[Settings > Airports API] Delete airport success',
  props<{ airportCode: string }>()
);

export const apiDeleteAirportFailed = createAction('[Settings > Airports API] Delete airport failed');

export const apiLoadAirportsOptionsSuccess = createAction(
  '[Settings > Airports Options API] Load airports options success',
  props<{ listOptions: AirportOption[] }>()
);

export const apiLoadAirportsOptionsFailed = createAction(
  '[Settings > Airports Options API] Load airports options failed'
);

export const airportsColumnsChanged = createAction(
  '[Settings > Airports Page] Columns Configuration changed',
  props<{ columns: DataGridColumn[] }>()
);

export const airportSelection = createAction(
  '[Settings > Airports Page] Airport(s) selected',
  props<{ selectedAirports: string[] }>()
);

export const updateBulkAirportsButtonClicked = createAction(
  '[Settings > Airports Page] Update bulk airports button clicked',
  props<{ airports: AirportsBulkUpdate }>()
);

export const apiUpdateBulkAirportsSuccess = createAction(
  '[Settings > Airport API] Bulk update of airports completed successfully',
  props<{ airports: Airport[] }>()
);

export const apiUpdateBulkAirportsFailed = createAction('[Settings > Airport API] Bulk update of airports failed');
