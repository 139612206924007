import { createAction, props } from '@ngrx/store';
import { Aircraft } from '@shared/models/aircrafts.model';

export const aircarftsAPIFetchingSuccess = createAction(
  '[Aircrafts API] Aircrafts fetched successfully using API',
  props<{ aircrafts: Aircraft[] }>()
);
export const aircraftsAPIFetchingFailed = createAction('[Aircrafts API] Aircrafts failed using API');

export const addAircraftButtonClicked = createAction(
  '[Aircrafts Page grid] Add aircraft button clicked',
  props<{ aircraft: Aircraft }>()
);
export const apiAddAircraftSuccess = createAction(
  '[Aircrafts API] Add aircraft success',
  props<{ aircraft: Aircraft }>()
);
export const apiAddAircraftFailed = createAction('[Aircrafts API] Aircrafts adding failed using API');

export const updateAircraftButtonClicked = createAction(
  '[Aircrafts Page grid] Update aircraft button clicked',
  props<{ aircraft: Aircraft }>()
);
export const apiUpdateAircraftSuccess = createAction(
  '[Aircrafts API] Update aircraft success',
  props<{ aircraft: Aircraft }>()
);
export const apiUpdateAircraftFailed = createAction('[Aircrafts API] Aircrafts updating failed using API');

export const deleteAircraftButtonClicked = createAction(
  '[Aircrafts Page grid] Delete aircraft button clicked',
  props<{ aircraftId: number }>()
);
export const apiDeleteAircraftSuccess = createAction(
  '[Aircrafts API] Delete aircraft success',
  props<{ aircraftId: number }>()
);
export const apiDeleteAircraftFailed = createAction('[Aircrafts API] Aircrafts deleting failed using API');
