import { createAction, props } from '@ngrx/store';
import { AirlineOperatingCertificate } from '@features/settings/airline-operating-certificate/airline-operating-certificate.model';

export const aocAPIFetchingSuccess = createAction(
  '[AOC API] AOC fetched successfully using API',
  props<{ aocs: AirlineOperatingCertificate[] }>()
);
export const aocAPIFetchingFailed = createAction('[AOC API] AOC failed using API');

export const addAOCButtonClicked = createAction(
  '[AOC Page grid] Add AOC button clicked',
  props<{ aoc: AirlineOperatingCertificate }>()
);
export const addingNewAOCSuccess = createAction(
  '[AOC API] AOC added Successfully using API',
  props<{ aoc: AirlineOperatingCertificate }>()
);
export const addingNewAOCFailed = createAction('[AOC API] AOC adding failed using API');

export const updateAOCButtonClicked = createAction(
  '[AOC Page grid] Update AOC button clicked',
  props<{ aoc: AirlineOperatingCertificate }>()
);
export const updatingAOCSuccess = createAction(
  '[AOC API] AOC updated Successfully using API',
  props<{ aoc: AirlineOperatingCertificate }>()
);
export const updatingAOCFailed = createAction('[AOC API] AOC updating failed using API');

export const removeAOCButtonClicked = createAction(
  '[AOC Page grid] Delete AOC button clicked',
  props<{ id: number }>()
);
export const removingAOCSuccess = createAction('[AOC API] AOC removed Successfully using API', props<{ id: number }>());
export const removingAOCFailed = createAction('[AOC API] AOC removing failed using API');
