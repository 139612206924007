import DataSource from 'devextreme/data/data_source';

export type MenuItemsEnum = 'edit' | 'clone' | 'delete';

export type FilterOperation =
  | '='
  | '<>'
  | '<'
  | '<='
  | '>'
  | '>='
  | 'contains'
  | 'endswith'
  | 'isblank'
  | 'isnotblank'
  | 'notcontains'
  | 'startswith'
  | 'between'
  | 'anyof'
  | 'noneof';

export interface DataGridColumn {
  field: string;
  displayField?: string;
  allowEmptyDisplayField?: boolean; // This option won't allow overriding displayField when the value is empty while add/edit rows
  fieldType?: 'string' | 'number' | 'boolean';
  cellType?:
    | 'dxo-lookup'
    | 'dx-tag-box'
    | 'color-picker'
    | 'toggle-with-icon'
    | 'days-frequency'
    | 'days-frequency-double-digit'
    | 'switch'
    | 'slot-days'
    | 'date'
    | 'decimal-number'
    | 'restricted-color-picker';
  nestedColumns?: DataGridColumn[];
  cellDataArray?: CellTemplateDataArray; // for dx-tag-box
  columnDataArray?: ColumnDataArray; // for dxo-lookup
  cellTemplate?: string;
  cellTemplatePlaceholder?: string;
  cellTemplateDisplay?: string;
  cellTemplateValue?: string;
  editCellTemplate?: string;
  itemTemplate?: string;
  alignment?: 'left' | 'right' | 'center';
  validation?: Validation[];
  caption?: string;
  width?: number;
  minWidth?: number;
  allowFiltering?: boolean;
  filterFunction?: (filterValue: unknown, selectedFilterOperation: FilterOperation) => Array<unknown>;
  allowEditing?: boolean;
  editorOptions?: EditorOptions;
  format?: string;
  extraText?: string;
  allowSorting?: boolean;
  iconUrl?: string;
  groupIndex?: number;
  wrapColumn?: boolean;
  decimalPoints?: number;
  setCellValue?: (newData: unknown, value: unknown) => void;
  daysOptions?: DaysOptions;
  visible?: boolean;
  isTruncated?: boolean;
}

export interface CellTemplateDataArray {
  dataSource?: DataSource; // for dx-tag-box
  displayExpr?: string;
  valueExpr?: string;
  placeholder?: string;
  searchEnabled?: boolean;
  maxDisplayedTags?: number;
  selectAllMode?: string;
  selectAllText?: string;
  showSelectionControls?: boolean;
}

export interface ColumnDataArray {
  dataArray: unknown;
  displayExpr: string;
  valueExpr: string;
  placeholder?: string;
}

export type ValidationType =
  | 'custom'
  | 'numeric'
  | 'async'
  | 'email'
  | 'pattern'
  | 'range'
  | 'required'
  | 'stringLength';

export type NewRowPosition = 'viewportTop' | 'viewportBottom' | 'pageTop' | 'pageBottom' | 'first' | 'last';

export interface Validation {
  type: ValidationType;
  message?: string;
  callback?: (options: ValidationOptions) => boolean;
  min?: number;
  max?: number;
  pattern?: RegExp;
}

export interface ValidationOptions {
  column: unknown;
  data: unknown;
  rule: unknown;
  validator: unknown;
  value: string;
}

export interface Selection {
  selectionMode: 'single' | 'multiple' | 'none';
  allowSelectAll: boolean;
}

export interface CongestionModel {
  code: string;
}

export interface EditorOptions {
  placeholder?: string;
  mode?: string;
  mask?: string;
  useMaskedValue?: boolean;
  useMaskBehavior?: boolean;
  showClearButton?: boolean;
  min?: number;
  max?: number;
}

export const CONGESTION: CongestionModel[] = [{ code: 'GREEN' }, { code: 'AMBER' }, { code: 'RED' }];

export interface DaysOptions {
  selectedDays?: number[];
  isEditable?: boolean;
  canUnselectAll?: boolean;
  canEnableDay?: boolean;
  canDisableDay?: boolean;
  unconnectedDays?: boolean;
}
