import { timezoneCustomValidation, validationIncrements } from '@shared/components/data-grid/data-grid.validations';
import { INCREMENT_VALIDATION_MSG } from '@utils/constants/validation-messages';
import { CONGESTION } from '@shared/components/data-grid/data-grid.interface';
import { COUNTRIES } from '@shared/models/country.model';
import { DataGridColumn } from '@shared/components/data-grid/data-grid.interface';

export const initialAirportsColumnsConfig: DataGridColumn[] = [
  {
    field: 'code',
    minWidth: 60,
    caption: 'Code',
    allowEditing: false,
    visible: true
  },
  {
    field: 'name',
    minWidth: 100,
    validation: [{ type: 'required' }],
    visible: false
  },
  {
    field: 'city',
    minWidth: 140,
    validation: [{ type: 'required' }],
    visible: true
  },
  {
    field: 'country',
    minWidth: 150,
    cellType: 'dxo-lookup',
    columnDataArray: { dataArray: COUNTRIES, displayExpr: 'name', valueExpr: 'code' },
    validation: [{ type: 'required' }],
    visible: true
  },
  {
    field: 'timezone',
    minWidth: 80,
    validation: [
      { type: 'required' },
      {
        type: 'custom',
        message: 'Only numbers and + - symbols allowed. Must be 5 characters.',
        callback: timezoneCustomValidation
      }
    ],
    visible: true
  },
  {
    field: 'min_ground_time',
    minWidth: 120,
    fieldType: 'number',
    validation: [
      { type: 'required' },
      {
        type: 'custom',
        message: INCREMENT_VALIDATION_MSG,
        callback: validationIncrements
      },
      {
        type: 'stringLength',
        min: 2,
        max: 3,
        message: 'Min ground time length should be between 2 and 3 characters.'
      }
    ],
    visible: true
  },
  {
    field: 'max_fleet_on_ground',
    minWidth: 110,
    fieldType: 'number',
    caption: 'Max on ground',
    validation: [
      {
        type: 'stringLength',
        max: 3,
        message: 'Max ground time length should be 3 characters.'
      }
    ],
    visible: false
  },
  {
    field: 'coordination_level',
    minWidth: 90,
    cellType: 'dxo-lookup',
    caption: 'Coord level',
    columnDataArray: {
      dataArray: [
        { value: 1, name: '1' },
        { value: 2, name: '2' },
        { value: 3, name: '3' },
        { value: 0, name: 'NA' }
      ],
      displayExpr: 'name',
      valueExpr: 'value'
    },
    visible: true
  },
  {
    field: 'max_movements_per_rolling_hour',
    minWidth: 90,
    fieldType: 'number',
    caption: 'Max movements per rolling hour',
    visible: true
  },
  {
    field: 'color',
    caption: 'Colour',
    minWidth: 80,
    allowFiltering: false,
    alignment: 'center',
    cellTemplate: 'colorTemplate',
    editCellTemplate: 'editColorTemplate',
    cellType: 'color-picker',
    itemTemplate: 'color-picker',
    visible: true
  },
  {
    field: 'congestion_level',
    caption: 'Congestion',
    minWidth: 100,
    allowFiltering: false,
    alignment: 'center',
    columnDataArray: { dataArray: CONGESTION, displayExpr: 'code', valueExpr: 'code' },
    itemTemplate: 'restricted-color-picker',
    cellTemplate: 'restrictedColorTemplate',
    editCellTemplate: 'editRestrictedColorTemplate',
    cellType: 'restricted-color-picker',
    visible: true
  },
  {
    field: 'schengen_crew',
    minWidth: 110,
    fieldType: 'boolean',
    alignment: 'center',
    visible: true
  },
  {
    field: 'departure_gap_minutes',
    minWidth: 130,
    caption: 'Dep Gap Mins',
    alignment: 'center',
    fieldType: 'number',
    validation: [
      {
        type: 'custom',
        message: INCREMENT_VALIDATION_MSG,
        callback: validationIncrements
      }
    ],
    visible: false
  },
  {
    field: 'arrival_gap_minutes',
    minWidth: 130,
    caption: 'Arr Gap Mins',
    alignment: 'center',
    fieldType: 'number',
    validation: [
      {
        type: 'custom',
        message: INCREMENT_VALIDATION_MSG,
        callback: validationIncrements
      }
    ],
    visible: false
  },
  {
    field: 'schengen',
    minWidth: 130,
    alignment: 'center',
    fieldType: 'boolean',
    visible: true
  }
];
